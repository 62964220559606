/**
Theme Name: Variant Landing Page
Theme URI: http://www.inkthemes.com/variant-landing-page-wordpress-theme/
Description: Variant is a Landing Page WordPress Theme that's perfect for creating a landing page website. Variant is completely responsive and gives a nice look to your website.
Version: 1.0.9
Author: InkThemes
Author URI: http://www.inkthemes.com/
License: GNU General Public License version 3
License URI: license.txt
Tags: black, blue, gray, pink, purple, white, yellow, dark, light, two-columns, right-sidebar, fixed-layout, responsive-layout, accessibility-ready, custom-menu, featured-images,  sticky-post, threaded-comments, translation-ready
Text Domain: variant-landing-page

Variant Landing Page WordPress theme, Copyright (C) 2015 InkThemes.com
Variant Landing Page WordPress theme is licensed under the GPL.
*/
html, body {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
}

h1 {
    margin: 0.67em 0;
    font-size: 30px;
}

h2 {
    margin: 0.83em 0;
    font-size: 26px;
}

h3 {
    margin: 1em 0;
    font-size: 24px;
}

h4 {
    margin: 1.33em 0;
    font-size: 22px;
}

h5 {
    margin: 1.67em 0;
    font-size: 20px;
}

h6 {
    margin: 2.33em 0;
    font-size: 18px;
}

p {
    color: #3d3d3d;
    margin: 1em 0;
}

ul, ol {
    margin: 1em 0;
}

b, strong {
    font-weight: normal;
    font-family: 'Raleway', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Raleway', sans-serif;
    color: #1a1a1a;
}

#content_wrapper h1, #content_wrapper h2, #content_wrapper h3, #content_wrapper h4, #content_wrapper h5, #content_wrapper h6 {
    line-height: 1.5em;
}

a {
    text-decoration: none;
    color: #1f96da;
}

a:hover {
    color: #136da0;
    text-decoration: none;
}

* {
    outline: 0;
}

*:hover {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
}

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0
}

.light-weight {
}

.border {
    background: url('assets/imgs/border.png') no-repeat center;
    height: 1px;
    width: 100%;
}

#content_wrapper .row.ftd {
    margin-left: -46px;
    margin-right: -46px;
}

#content_wrapper .col-ftd {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 15px;
}

/* #Header
===================================================*/
#header {
    background: #25292c;
    position: relative;;
}

#sub_header {
    border-top: 1px solid #1d1d1d;
}

#sub_header .st-title-wrap {
    text-align: center;
    color: #fff;
    padding-top: 40px;
    padding-bottom: 35px;
}

#sub_header .st-title-wrap h1 {
    color: #fff;
}

#sub_header .page-description,
#sub_header .page-description p {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
}

#sub_header #crumbs {
    color: #CAC8C8;
}

#header .header_wrapper {
    z-index: 1;
}

#header .top-header {
    padding-top: 14px;
    padding-bottom: 7px;
    position: relative;
    border-bottom: 1px solid #424242;
}

#header .top-header .logo {
    margin-top: 13px;
}

/* =Superfish Menu
======================================*/
#header .menu-wrap {
    padding-top: 4px;
}

.menu_bar {
    border: 1px solid #d9d9d9;
    background: #fff;
    display: block;
    padding-bottom: 20px;
    padding-top: 5px;
    padding-left: 20px;
    margin-top: 15px;
}

#menu {
    position: relative;
}

#menu ul {
    list-style-type: none;
}

#menu .sf-menu {
    list-style: none;
    position: relative;
    z-index: 100;
    margin: 8px 1px 10px 7px;
    text-align: right;
}

#menu .sf-menu li > ul li > ul {
    margin-top: 1px;
}

#menu .sf-menu li {
    position: relative;
    display: inline-block;
    font-size: 16px;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 15px;
}

#menu .sf-menu li:last-child {
    margin-right: 0;
}

#menu .sf-menu li a {
    position: relative;
    display: block;
    color: #eee;
    text-transform: uppercase;
    text-decoration: none;
    padding: 6px 11px 6px 11px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    font-weight: 200;
    font-size: 18px;
    font-family: 'Raleway', sans-serif;

}

#menu li.current-menu-item a,
#menu li a.selected,
#menu li.current-menu-parent a,
#menu li.current_page_parent a,
#menu li a.selected,
#menu li a:hover,
#menu li.current_page_item a {
    color: #000;
    background: #ffffff;
}

#menu .sf-menu li > ul {
    margin-top: -15px;
    margin-left: 0;
}

#menu li li a,
#menu li li a.sf-with-ul,
#menu li li a:hover,
#menu li li a.selected {
    color: #000;
    background: #ffffff;
}

#menu li.sfHover a.sf-with-ul,
#menu li li.sfHover a.sf-with-ul {

}

#menu li.current-menu-item a:hover,
#menu li.current-menu-parent a:hover,
#menu li.current_page_parent a:hover,
#menu li a:hover.selected {
    color: #000;
    background: #ffffff;
}

#menu .sf-menu li.current-menu-item a,
#menu .sf-menu li.menu-item-home a {
    margin-right: 0;
}

#menu .sf-menu li li:first-child {
}

#menu .sf-menu li li {
    font-size: 15px;
    line-height: 21px;
    text-transform: capitalize;
    margin: 0;
    padding: 0;
}

#menu .sf-menu li li a {
    height: auto;
    display: block;
    text-align: left;
    color: #FFFFFF;
    margin: 0;
    padding: 10px 18px;
    text-decoration: none;
    position: relative;
}

* html #menu .sf-menu li li a {
    display: inline-block;
}

#menu .sf-menu li li a:link,
#menu .sf-menu li li a:visited {
    color: #000;
}

#menu .sf-menu li li a.selected, #menu .sf-menu li li a:hover {
    color: #000;
    background: rgba(255, 255, 255, 0.65);
}

#menu .sf-menu li ul li {
    display: list-item;
    float: none;
}

#menu .sf-menu li ul li ul {
    padding-top: 0;
    background: none;
    top: 0;
    margin-top: 0;
    margin-left: 0;
}

#menu .sf-menu li ul li ul li:first-child {
    padding-top: 0;
}
.sf-menu ul {
    position: absolute;
    top: -900em;
    width: 10em; /* left offset of submenus need to match (see below) */
    padding: 0;
    position: absolute;
    left: -999em;
    width: 200px;
    background-color: #fff;
}

.sf-menu li:hover ul,
.sf-menu li.sfHover ul {
    left: 0;
    top: 3.3em; /* match top ul list item height */
    z-index: 99;
}

ul.sf-menu li:hover li ul,
ul.sf-menu li.sfHover li ul {
    top: -999em;
}

ul.sf-menu li li:hover ul,
ul.sf-menu li li.sfHover ul {
    left: 200px; /* match ul width */
    top: 0;
    background-color: #fff;
}

#menu .sf-menu li a:hover {

}

#menu .sf-menu li li a.selected, #menu .sf-menu li li a:hover {
    /*CSS class that's dynamically added to the currently active menu items' LI A element*/
    color: #000;
    background: rgba(255, 255, 255, 0.65);
}

#menu li.current-menu-item a, #menu li.current-menu-parent a, #menu li.current_page_parent a, #menu li a.selected, #menu li a:hover, #menu li.current_page_item a, #menu li.sfHover, #menu li.sfHover a {
    color: #000;
    background: #ffffff;
}

#menu .ddsmoothmenu li li {
    background: #eee;
}

@-webkit-keyframes bounceIn_custom {
    0% {
        -webkit-transform: scale(1);
    }
    25% {
        -webkit-transform: scale(.8);
    }
    50% {
        -webkit-transform: scale(1.05);
    }
    70% {
        -webkit-transform: scale(.9);
    }
    100% {
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes bounceIn_custom {
    0% {
        -moz-transform: scale(1);
    }
    25% {
        -moz-transform: scale(.8);
    }
    50% {
        -moz-transform: scale(1.05);
    }
    70% {
        -moz-transform: scale(.9);
    }
    100% {
        -moz-transform: scale(1);
    }
}

@-o-keyframes bounceIn_custom {
    0% {
        -o-transform: scale(1);
    }
    25% {
        -o-transform: scale(.8);
    }
    50% {
        -o-transform: scale(1.05);
    }
    70% {
        -o-transform: scale(.9);
    }
    100% {
        -o-transform: scale(1);
    }
}

@keyframes bounceIn_custom {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(.8);
    }
    50% {
        transform: scale(1.05);
    }
    70% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}

/* =Footer
======================================*/
#footer {
    background-color: #525252;
    border-bottom: 3px solid #6e6e6e;
    padding-top: 18px;
    padding-bottom: 18px;
}

#footer .rmm-main-list {
    list-style-type: none;
    padding: 0;
    text-align: center;
}

#footer .rmm-main-list li {
    display: inline-block;
    margin-right: 18px;
}

#footer .rmm-main-list li a {
    color: #b5e1ff;
    font-size: 17px;
}

#footer .rmm-main-list li a:hover {
    color: #fff;
}

/* =Footer Bottom
======================================*/
#footer_bottom {
    background-color: #2C2C2C;
    padding-top: 18px;
    padding-bottom: 17px;
}

#footer_bottom .copyright {
    text-align: center;
    font-size: 20px;
    color: #bbb;
}

#footer_bottom .copyright a {
    color: #fff;
    font-size: 19px;
}

.footer, .footer p {
    color: #fff;
    font-size: 17px;
}

.footer h4 {
    color: #fff;
}

.footer ul {
    list-style: none;
    padding-left: 0;
}

.footer ul li {
    margin-left: 0;
    margin-bottom: 12px;
}

.footer ul li a, .footer a {
    color: #fff;
}

.footer .searchform {
    position: relative;
    width: 252px;
    margin-bottom: 10px;
    background: #fff;
}

.footer .searchform #s {
    height: 37px;
    width: 202px;
    padding-left: 8px;
    padding-right: 5px;
    background: none;
    border: none;
    color: #666;
}

.footer .searchform #ssubmit {
    background: url('assets/imgs/search.png') no-repeat;
    border: none;
    position: absolute;
    top: 7px;
    right: 0px;
    height: 31px;
    width: 31px;
}

/* =Sidebar
======================================*/
#sidebar {
    margin-bottom: 50px;
    margin-left: 35px;
}

#sidebar img {
    max-width: 100%;
    height: auto;
}

/* =Default Content
======================================*/
#content_wrapper.content {
    padding-top: 70px;
    background-color: #f6f6f6;
    padding-bottom: 70px;
}

#content_wrapper .page-content.fullwidth {
    overflow: hidden;
}

#content_wrapper .page-content {
    border: 1px solid #e2e2e2;
    background-color: #fff;
    padding: 30px 40px 30px 40px;
    -webkit-box-shadow: 0px 3px 0px 0px rgba(211, 211, 211, 1);
    -moz-box-shadow: 0px 3px 0px 0px rgba(211, 211, 211, 1);
    box-shadow: 0px 3px 0px 0px rgba(211, 211, 211, 1);
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 1.6em;
}

#content_wrapper .page-content h1,
#content_wrapper .page-content h2,
#content_wrapper .page-content h3,
#content_wrapper .page-content h4,
#content_wrapper .page-content h5,
#content_wrapper .page-content h6 {
    margin-top: 0;
}

#content_wrapper .page-content img {
    max-width: 100%;
    height: auto;
}

/* =Extension Page
======================================*/
#content_wrapper.content .sidebar-page {
    border: 1px solid #e2e2e2;
    background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-right: 0px;
    margin-bottom: 40px;
}

#content_wrapper.content .sidebar-banner {
    margin-right: 12px;
}

#content_wrapper.content .sidebar-page .feature-menu {
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
}

#content_wrapper.content .sidebar-page .sidebar-title {
    display: block;
    border-bottom: 1px solid #e2e2e2;
    font-size: 20px;
    padding: 15px 16px 13px 16px;
    font-family: 'Montserrat', sans-serif;
}

.footer .sidebar-title {
    display: block;
    border-bottom: 1px solid #e2e2e2;
    font-size: 20px;
    padding: 15px 16px 13px 0px;
}

.footer .textwidget {
    margin-top: 15px;
}

.footer #searchForm {
    width: 95%;
    margin: 0;
    padding-left: 0;
}

#content_wrapper.content .sidebar-page ul {
    padding: 0;
    list-style-type: none;
}

#content_wrapper.content .sidebar-page li {
    font-size: 17px;
    padding: 14px 16px 13px 16px;
    border-bottom: 1px solid #e2e2e2;
}

#content_wrapper.content .sidebar-page ul li a {
    font-size: 17px;
    color: #3D3D3D;
    display: block;
}

#content_wrapper.content .sidebar-page ul li:hover,
#content_wrapper.content .sidebar-page ul li.current {
    background-color: #333;
    color: #fff;
}

#content_wrapper.content .sidebar-page ul li:hover a {
    color: #fff;
}

#content_wrapper.content .sidebar-page ul li:last-child {
    border-bottom: 0;
}

#content_wrapper.content .sidebar-page ul li .glyphicon {
    font-size: 16px;
    color: #1473b3;
    margin-right: 18px;
}

#content_wrapper.content .sidebar-page ul li:hover .glyphicon,
#content_wrapper.content .sidebar-page ul li.current .glyphicon {
    color: #fff;
}

#content_wrapper.content .sidebar-page .parent {
    position: relative;
}

#content_wrapper.content .sidebar-page .parent .glyphicon.symbol {
    position: absolute;
    top: 17px;
    right: -3px;
}

#content_wrapper.content .sidebar-page .term-children {
}

#content_wrapper.content .cnt-data h1,
#content_wrapper.content .cnt-data h2,
#content_wrapper.content .cnt-data h3,
#content_wrapper.content .cnt-data h4,
#content_wrapper.content .cnt-data h5,
#content_wrapper.content .cnt-data h6 {
    margin-top: 0;
}

#content_wrapper.content .cnt-data {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.5em;
}

#content_wrapper.content .cnt-data ul {
    margin-bottom: 25px;
}

#content_wrapper.content .element-list {
}

#content_wrapper.content .element-list .element-content {
    padding: 30px;
    text-align: center;
    border: 1px solid #dadada;
    -webkit-box-shadow: 0px 2px 0px 0px rgba(229, 229, 229, 1);
    -moz-box-shadow: 0px 2px 0px 0px rgba(229, 229, 229, 1);
    box-shadow: 0px 2px 0px 0px rgba(229, 229, 229, 1);
    padding-bottom: 28px;
    margin-bottom: 44px;
    background-color: #fff;
}

#content_wrapper.content .element-list .element-content .element-thumbnail {
    margin-bottom: 20px;
    width: 241px;
    height: auto;
    max-width: 100%;
}

#content_wrapper.content .element-list .element-content {
    color: #232323;
    font-size: 16px;
    line-height: 1.6em;
    margin-bottom: 46px;
    height: 285px;
}

#content_wrapper.content .element-list .element-content img {
    max-width: 100%;
    height: auto;
}

#content_wrapper.content .element-list .element-content .element-des {
    margin-top: 18px;
}

#content_wrapper.content .element-list .element-content .fbtn {
    text-transform: uppercase;
    font-size: 15px;
}

#content_wrapper .element-list .row.elm {
    margin-left: -12px;
    margin-right: -12px;
}

#content_wrapper .element-list .col-elm {
    padding-right: 12px;
    padding-left: 12px;
}

#content_wrapper.content .cnt-wrap {
}

/* =Extension Page [single]
======================================*/
#content_wrapper.content .element-list.single .element-content {
    padding: 17px;
    padding-bottom: 28px;
}

#content_wrapper .element-list.single .row.elm {
    margin-left: -30px;
    margin-right: -30px;
}

#content_wrapper .element-list.single .col-elm {
    padding-right: 30px;
    padding-left: 30px;
}

#content_wrapper.content.single .cnt-data {
    background-color: #fff;
    padding: 18px 16px 18px 16px;
    -webkit-box-shadow: 0px 3px 0px 0px rgba(211, 211, 211, 1);
    -moz-box-shadow: 0px 3px 0px 0px rgba(211, 211, 211, 1);
    box-shadow: 0px 3px 0px 0px rgba(211, 211, 211, 1);
    margin-bottom: 50px;
    border: 1px solid #e2e2e2;
}

#content_wrapper.content.single .cnt-data img {
    margin-bottom: 36px;
    max-width: 100%;
    height: auto;
}

#content_wrapper.content.single .cnt-sidebar {
    background-color: #f1f1f1;
    padding: 22px 28px 22px 28px;
    -webkit-box-shadow: 0px 3px 0px 0px rgba(211, 211, 211, 1);
    -moz-box-shadow: 0px 3px 0px 0px rgba(211, 211, 211, 1);
    box-shadow: 0px 3px 0px 0px rgba(211, 211, 211, 1);
    margin-left: 7px;
    margin-bottom: 38px;
}

#content_wrapper.content.single .cnt-sidebar img {
    max-width: 100%;
    height: auto;
}

#content_wrapper.content.single .cnt-sidebar .elm-dtl {
    display: block;
    font-size: 18px;
    margin-bottom: 18px;
}

#content_wrapper.content.single .cnt-sidebar .elm-des {
    font-size: 16px;
    color: #3b3b3b;
    line-height: 1.6em;
    margin-bottom: 24px;
}

#content_wrapper.content.single .cnt-sidebar .elm-price {
    display: block;
    font-size: 18px;
    margin-bottom: 12px;
}

#content_wrapper.content.single .cnt-sidebar #elm_form {
    margin-bottom: 20px;
}

#content_wrapper.content.single .cnt-sidebar #elm_form label {
    color: #3b3b3b;
    font-size: 16px;
    font-weight: normal;
    display: block;
}

#content_wrapper.content.single .cnt-sidebar .fbtn {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 24px;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
}

#content_wrapper.content.single .cnt-sidebar .lnc-fl {
    margin-bottom: 6px;
}

#content_wrapper.content.single .cnt-sidebar .lnc-fl,
#content_wrapper.content.single .cnt-sidebar .lnc-term {
    text-align: center;
    display: block;
    font-size: 15px;
}

#content_wrapper.content.single .wyf-wrap {
    margin-top: 92px;
    margin-bottom: 50px;
}

#content_wrapper.content .wyf-wrap .wyf {
    background-color: #fafafa;
    font-size: 32px;
    color: #14659c;
}

/* =Form Example Page [single]
======================================*/
#content_wrapper.content .cnt-data.form-exp {
    background: transparent;
    padding: 0 10px 18px 10px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 0;
}

/* =Blog Page [sidebar]
======================================*/
#content_wrapper .sidebar-page .textwidget {
    font-size: 18px;

    padding: 18px 19px 22px 19px;
    line-height: 1.6em;
    color: #0d0d0d;
}

#content_wrapper.content .blog {
    padding-left: 0;
    padding-right: 0px;
}

#content_wrapper.content .blog.page {
    padding-left: 20px;
}

#content_wrapper.content .post {
    border: 1px solid #e2e2e2;
    background-color: #fff;
    padding: 10px 38px 30px 38px;
    margin-bottom: 50px;
    position: relative;
}

#content_wrapper.content .post .post-title {
    font-size: 32px;
    color: #1a1a1a;
    line-height: 1.4em;
    margin-bottom: 20px;
}

#content_wrapper.content .post .post-title a {
    color: #1a1a1a;
}

#content_wrapper.content .post .post-meta {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
    overflow: hidden;
}

#content_wrapper.content .post .post-meta li {
    display: inline-block;
}

#content_wrapper.content .post .post-meta li a,
#content_wrapper.content .post .post-meta li {
    color: #666565;
    font-style: italic;
    font-size: 16px;
}

#content_wrapper.content .post .post-meta li a {
    display: inline-block;
    margin-left: 5px;
}

#content_wrapper.content .post .post-meta .posted-by {
    float: left;
}

#content_wrapper.content .post .post-meta .post-comment {
    float: right;
}

#content_wrapper.content .post .post-meta li a:hover,
#content_wrapper.content .post .post-title a:hover {
    color: #136da0;
}

#content_wrapper.content .post .author-avatar {
    /*position: absolute;
    right: -120px;
    top:0;*/
    text-align: center;
}

#content_wrapper.content .post .author-avatar img {
    width: 81px;
    height: 81px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
}

#content_wrapper.content .post .author-avatar .author-name {
    display: block;
    margin-top: 8px;
}

#content_wrapper.content .post .author-avatar .author-name a {
    color: #1a1a1a;
    font-size: 14px;
}

#content_wrapper.content .post .post-thumbnail {
    margin-bottom: 38px;
}

#content_wrapper.content .post .post-thumbnail img {
    max-width: 100%;
    height: auto;
}

#content_wrapper.content .post .post-content,
#content_wrapper.content .post p {
    font-size: 18px;
    color: #4d4d4d;
    line-height: 1.8em;
}

#content_wrapper.content .post .post-content img {
    max-width: 100%;
    height: auto;
}

#content_wrapper.content .post .post-content a {
    text-decoration: underline;
}

#content_wrapper.content .post .read-more {
    display: inline-block;
    float: right;
    color: #222;
    padding: 11px 27px 11px 29px;
    font-size: 16px;
    clear: both;
    border: 1px solid #1d1d1d;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#content_wrapper.content .post .read-more:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1d1d1d;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

#content_wrapper.content .post .read-more:hover, #content_wrapper.content .post .read-more:focus, #content_wrapper.content .post .read-more:active {
    color: white;
}

#content_wrapper.content .post .read-more:hover:before, #content_wrapper.content .post .read-more:focus:before, #content_wrapper.content .post .read-more:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* =Comments
======================================*/
#comment_list {
    margin: 0;
}

#comment_list ol.commentlist {
    clear: both;
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
}

#comment_list ol.commentlist li.comment {
    line-height: 18px;
}

#comment_list ol.commentlist li.comment .comment-body {
    position: relative;
    padding-left: 25px;
    padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    border: 1px solid #d0d0d0;
    -moz-box-shadow: 0 2px 0 #e6e6e6;
    box-shadow: 0 2px 0 #e6e6e6;
    margin-bottom: 40px;
    background-color: #fff;
}

#comment_list ol.commentlist li.comment .comment-author a:link, #comment_list ol.commentlist li.comment .comment-author a:visited {
    color: #000;
    font-weight: 700;
    text-decoration: none !important;
}

#comment_list ol.commentlist li.comment .comment-author.vcard {
    font-size: 18px;
    margin-bottom: 12px;
    color: #1a1a1a;
}

#comment_list ol.commentlist li.comment .comment-meta.commentmetadata {
    margin-bottom: 16px;
}

#comment_list cite.fn {
    color: #000;
    font-style: normal;
}

#comment_list ol.commentlist li.comment .comment-author .avatar {
    float: left;
    margin-right: 16px;
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
    border: 1px solid #D5D5D5;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

#comment_list ol.commentlist li.comment .comment-meta a {
    color: #808080;
    text-decoration: none !important;
    font-size: 14px;
}

#comment_list ol.commentlist li.comment p {
    line-height: 28px;
    margin-top: 5px;
    color: #373737;
    font-size: 16px;
    margin-bottom: 15px;
}

#comment_list ol.commentlist li.comment .reply {
    margin-top: 10px;
    text-align: right;
    padding-bottom: 10px;
    display: block;
}

#comment_list ol.commentlist li.comment .reply a {
    color: #fff;
    font-size: 16px;
    background-color: #4987bc;
    padding: 6px 18px 6px 18px;
    position: relative;
    z-index: 16;
    text-transform: uppercase;
}

#comment_list ol.commentlist li.comment .reply a:hover {
    background-color: #6ea5d4;
}

#comment_list ol.commentlist li.comment .reply a .glyphicon {
    position: absolute;
    right: -7px;
    top: 30%;
    color: #4987bc;
    z-index: 0;
}

#comment_list ol.commentlist li.comment .reply a:hover .glyphicon {
    color: #6ea5d4;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
}

#comment_list ol.commentlist li.comment ul.children {
    list-style: none;
    text-indent: 0;
    margin: 1em 0 0;
}

#comment_list .comment-nav {
    height: 20px;
    padding: 5px;
}

#comment_list .comment-nav a:link, #comment_list .comment-nav a:visited {
    color: #f96e02;
}

#commentsbox {
    margin-top: 60px;
}

#commentsbox h2.comments-heading,
#comment_list h2.comments-heading {
    font-size: 26px;
    color: #1a1a1a;
    margin-bottom: 30px;
}

#commentsbox h2.comments-heading span,
#comment_list h2.comments-heading span {
    padding-right: 30px;
    display: inline-block;
}

#respond {
    margin: 10px 0;
    padding: 0;
}

#respond h3 {
    font-size: 14px;
    color: #000;
    font-weight: 700;
    padding: 0;
    padding-left: 0;
}

#commentform {
}

#commentform .form-row {
    margin-bottom: 30px;
}

#commentform p {
    color: #444;
}

#commentform a {
    color: #0454A8;
}

#respond label {
    color: #322903;
    padding: 5px 0;
    display: block;
}

#respond input[type="text"],
#respond input[type="url"],
#respond input[type="email"],
.contactform input[type="text"],
.contactform input[type="email"] {
    width: 58%;
    height: 45px;
    display: block;
    background: #fff;
    border: 1px solid #cccccc;
    margin-bottom: 0;
    -webkit-box-shadow: 0 2px 0 #e6e6e6;
    -moz-box-shadow: 0 2px 0 #e6e6e6;
    box-shadow: 0 2px 0 #e6e6e6;
    padding-left: 5px;
    color: #000000;
    font-size: 18px;
}

#respond input[type="text"]:focus,
#respond input[type="url"]:focus,
#respond input[type="email"]:focus,
.contactform input[type="text"]:focus,
.contactform input[type="email"]:focus {
    border-color: #92b1cb;
}

#respond ::-webkit-input-placeholder {
    color: #000000;
    font-size: 18px;
}

#respond input[type="text"]:focus ::-webkit-input-placeholder,
#respond input[type="url"]:focus ::-webkit-input-placeholder,
#respond input[type="email"]:focus ::-webkit-input-placeholder {
    color: #4d6b85;
}

#respond input[type="text"]:focus :-ms-input-placeholder,
#respond input[type="url"]:focus :-ms-input-placeholder,
#respond input[type="email"]:focus :-ms-input-placeholder {
    color: #4d6b85;
}

#respond :-moz-placeholder {
    /* Firefox 18- */
    color: #000000;
    font-size: 18px;
}

#respond ::-moz-placeholder {
    /* Firefox 19+ */
    color: #000000;
    font-size: 18px;
}

#respond :-ms-input-placeholder {
    color: #000000;
    font-size: 18px;
}

.contactform ::-webkit-input-placeholder {
    color: #000000;
    font-size: 18px;
}

.contactform input[type="text"]:focus ::-webkit-input-placeholder {
    color: #4d6b85;
}

.contactform input[type="text"]:focus :-ms-input-placeholder {
    color: #4d6b85;
}

.contactform :-moz-placeholder {
    /* Firefox 18- */
    color: #000000;
    font-size: 18px;
}

.contactform ::-moz-placeholder {
    /* Firefox 19+ */
    color: #000000;
    font-size: 18px;
}

.contactform :-ms-input-placeholder {
    color: #000000;
    font-size: 18px;
}

#respond input#commentSubmit, .contactform input.submit {
    border: 0;
    color: #fff;
    padding: 6px 15px 4px 15px;
    border-radius: 4px;
    font-size: 23px;
    background-color: #2c95dc;
    webkit-box-shadow: 0 3px 0 #09466f;
    -moz-box-shadow: 0 3px 0 #09466f;
    box-shadow: 0 3px 0 #09466f;
}

#respond input#commentSubmit:hover, .contactform input.submit:hover {
    background-color: #09466f;
}

textarea#comment, .contactform textarea {
    width: 100%;
    clear: both;
    display: block;
    background: #fff;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding-left: 10px;
    color: #6a6565;
    -webkit-box-shadow: 0 2px 0 #e6e6e6;
    -moz-box-shadow: 0 2px 0 #e6e6e6;
    box-shadow: 0 2px 0 #e6e6e6;
    margin-bottom: 0;
    height: 222px;
}

textarea#comment:focus {
    border-color: #92b1cb;
}

.contactform input[type="text"], .contactform input[type="email"], .contactform textarea {
    margin-bottom: 35px;
}

/* =Pagination
======================================*/
ul.page-numbers {
    list-style-type: none;
    padding-left: 0;
    float: right;
    margin-top: 0;
}

ul.page-numbers li {
    display: inline-block;
}

ul.page-numbers li .current, ul.page-numbers li a:hover {
    background-color: #333;
    color: #fff;
}

ul.page-numbers li a, ul.page-numbers li span {
    padding: 7px 7px 4px 14px;
    background: #E0E0E0;
    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    border-radius: 70px;
    text-decoration: none;
    width: 36px;
    height: 34px;
    display: inline-block;
    margin-right: 8px;
    color: #777272;
}

/* =Search Form
======================================*/
#searchForm {
    -webkit-transition: all 0.3s ease-in-out;
    -webkit-transition-delay: 0;
    -moz-transition: all 0.3s ease-in-out 0;
    -o-transition: all 0.3s ease-in-out 0;
    transition: all 0.3s ease-in-out 0;
    padding: 1.3em 0.8em 1em;
    max-width: 44em;
    margin-left: auto;
    margin-right: auto;
    display: block;
    float: none;
    width: 93%;
}

#searchForm .search-form {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    display: block;
    position: relative;
    height: 2.8em;
    background-color: #fff;
    border: 1px solid #cccdc8;
    position: relative;
}

/*#searchForm .search-form label{
    visibility: hidden;;
}*/
#searchForm .search-form .search-field {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 1.1em;
    font-weight: normal;
    display: block;
    width: 78%;
    background: none;
    outline: none;
    border: none;
    padding: 0;
    height: 2.54545em;
    z-index: 1;
    position: relative;
    top: -1px;
    padding-left: 0.75em;
}

#searchForm .search-form .search-field:focus,
#searchForm .search-form .search-submit:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

#searchForm .search-form .search-submit {
    background-image: url('assets/imgs/search.png');
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    min-width: 54px;
    color: #838383;
    font-size: 1.25em;
    padding: 0 0.64em;
    height: auto;
    min-height: 1.5em;
    margin-top: 2px;
    margiearance: none;
    -o-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 1em;
    display: block;
    cursor: pointer;
    text-align: center;
    border: none;
    height: 2em;
    line-height: 2.45em;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2px;
    left: auto;
    margin: auto;
    z-index: 2;
    outline: none;
    n-bottom: 2px;
    line-height: 1.5;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 1em;
    display: block;
    cursor: pointer;
    text-align: center;
    border: none;
    height: 2em;
    line-height: 2.45em;
    position: absolute;
    top: 1px;
    bottom: 0;
    right: 1px;
    left: auto;
    margin: auto;
    z-index: 2;
    outline: none;
}

#searchForm .search-form .search-submit:hover {
    background-color: #1f96da;
    color: white;
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

#nav-single {
    overflow: hidden;
    margin-bottom: 10px;
}

#nav-single .nav-previous {
    float: left;
    width: 50%;
}

#nav-single .nav-next {
    float: right;
    text-align: right;
    width: 50%;
}

.sticky {
}

.gallery-caption {
}

.bypostauthor {
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

/* Text meant only for screen readers */
.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    left: -999em;
}

.screen-reader-text:focus {
    clip: auto !important;
    display: block;
    height: auto;
    left: 5px;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
    font-size: 14px;
    font-weight: 700;
    padding: 15px 23px 14px;
    background: #f1f1f1;
    color: #21759b;
    line-height: normal;
    text-decoration: none;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
}

.animated {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 0;
}

.animated.left-to-right,
.animated.right-to-left,
.animated.bottom-to-top {
    -webkit-transition-duration: 1.2s;
    -moz-transition-duration: 1.2s;
    -o-transition-duration: 1.2s;
    -ms-transition-duration: 1.2s;
    transition-duration: 1.2s;
    -webkit-animation-duration: .8s;
    -webkit-animation-delay: .2s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: .8s;
    -moz-animation-delay: .2s;
    -moz-animation-timing-function: ease-in-out;
    -moz-animation-fill-mode: both;
    -o-animation-duration: .8s;
    -o-animation-delay: .2s;
    -o-animation-timing-function: ease-in-out;
    -o-animation-fill-mode: both;
    -ms-animation-duration: .8s;
    -ms-animation-delay: .2s;
    -ms-animation-timing-function: ease-in-out;
    -ms-animation-fill-mode: both;
    animation-duration: .8s;
    animation-delay: .2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    -ms-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    opacity: 1;
}

.animation_started.animated {
    -webkit-animation-duration: .4s;
    -webkit-animation-delay: .2s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: .4s;
    -moz-animation-delay: .2s;
    -moz-animation-timing-function: ease-in-out;
    -moz-animation-fill-mode: both;
    -o-animation-duration: .4s;
    -o-animation-delay: .2s;
    -o-animation-timing-function: ease-in-out;
    -o-animation-fill-mode: both;
    -ms-animation-duration: .4s;
    -ms-animation-delay: .2s;
    -ms-animation-timing-function: ease-in-out;
    -ms-animation-fill-mode: both;
    animation-duration: .4s;
    animation-delay: .2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    -ms-animation-name: fadeInUp;
    animation-name: fadeInUp;
    opacity: 1;
}

.left-to-right.animated {
    -webkit-transform: scale(1) translate3d(-50%, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 0;
    opacity: 0;
}

.animation_started.left-to-right.animated {
    -webkit-animation-duration: .8s;
    -webkit-animation-delay: .2s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: .8s;
    -moz-animation-delay: .2s;
    -moz-animation-timing-function: ease-in-out;
    -moz-animation-fill-mode: both;
    -o-animation-duration: .8s;
    -o-animation-delay: .2s;
    -o-animation-timing-function: ease-in-out;
    -o-animation-fill-mode: both;
    -ms-animation-duration: .8s;
    -ms-animation-delay: .2s;
    -ms-animation-timing-function: ease-in-out;
    -ms-animation-fill-mode: both;
    animation-duration: .8s;
    animation-delay: .2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    -ms-animation-name: fadeInUp;
    animation-name: fadeInLeft;
    opacity: 1;
}

.right-to-left {
    -webkit-transform: scale(1) translate3d(50%, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 0;
    opacity: 0;
}

.bottom-to-top {
    -webkit-transform: scale(1) translate3d(0, 50%, 0);
    opacity: 0;
}

@-webkit-keyframes fadeInUp_custom {
    0% {
        opacity: 0;
        -webkit-transform: translateY(270px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInUp_custom {
    0% {
        opacity: 0;
        -moz-transform: translateY(270px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInUp_custom {
    0% {
        opacity: 0;
        -o-transform: translateY(270px);
    }

    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInUp_custom {
    0% {
        opacity: 0;
        transform: translateY(270px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/*Calendar style*/
#wp-calendar caption {
    text-align: center;
    background: #17517a;
    color: #fff;
    border-bottom: 2px solid #e6eef7;
}

#wp-calendar {
    color: #555;
    width: 95%;
    text-align: center;
    color: #fff;
}

#wp-calendar a {
    color: #000;
    color: #17517a;
}

.footer #wp-calendar a {
    color: #17517a;
}

#wp-calendar caption, #wp-calendar td, #wp-calendar th {
    text-align: center;
}

#wp-calendar caption {
    font-size: 16px;
    font-weight: 500;
    padding: 5px 0 3px 0;
    text-transform: uppercase;
}

#wp-calendar th {
    background: #f4f4f4;
    font-weight: bold;
    border: solid 1px #e2e2e3;
    color: #aaa;
}

.footer #wp-calendar th {
    background: #e0e0e0;
    border: solid 1px #e2e2e3;
}

#wp-calendar tfoot td {
    background: #f4f4f4;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border: solid 1px #f4f4f4;
    color: #fff;
}

.footer #wp-calendar tbody td {
    color: #000;
}

.footer #wp-calendar tbody td#next.pad {
    padding-right: 5px;
}

#wp-calendar tbody td {
    color: #c0c0c0;
    padding: 5px;
    background: #e9eaeb;
    border: solid 1px #fcfcfd;
}

#wp-calendar tbody td.pad {
    background-color: #f9fafb;
}

#wp-calendar tbody td#today {
    background-color: #fdfdfd;
}

#calendar_wrap {
    display: block;
    margin: 20px 0px 20px 10px;
}

/*404 page Style
=======================================================*/
h2.error_pera {
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 24px;
}

.error_list {
    margin-bottom: 25px;
    text-align: left;
}

.error_list a, .error_list {
    color: #1d1d1d;
}

.error_list ul {
    list-style: none;
    padding-left: 0;
}

.error_list ul li {
    margin-left: 0;
    padding: 9px 0 9px 0;
    padding-left: 0px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;

}

.error_list ul li:hover {
}

.error_list ul li a {
    font-size: 16px;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.error_list ul li:last-child {
    border-bottom: none;
}

.error_list ul li a:hover {
    color: #f05931;
}

/*Gallery page
================================================*/
.page-content .gallery .thumbnail {
    margin-left: 0;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    margin: 0 auto;
    margin-right: 10px;
    width: 107%;
    border: none;
    box-shadow: none;
}

.page-content .gallery ul.thumbnail li {
    margin: 0;
    margin-left: 0;
    float: left;
    position: relative;
    margin-right: 26px;
    width: 216px;
    height: auto;
    margin-bottom: 25px;
    text-align: center;
    padding-bottom: 0px;
}

.page-content .gallery .thumbnail li img {
    margin-right: 0;
    width: 260px;
    height: 190px;
}

.page-content .gallery ul.thumbnail.col-1 li {
    width: 260px;
    height: auto;
    display: block;
    float: none;
    margin: 0 auto;
}

.page-content .gallery ul.thumbnail.col-2 li {
    width: 260px;
    margin-left: 132px;
    margin-right: 165px;
    margin-bottom: 120px;
    height: auto;
}

.page-content .gallery ul.thumbnail.col-3 li {
    width: 315px;
    margin-right: 9%;
    height: auto;
    margin-bottom: 6%;
}

.page-content .gallery ul.thumbnail.col-4 li {
    width: 260px;
    height: auto;
    margin-right: 5%;
    margin-bottom: 24px;
}

.page-content .gallery .thumbnail.col-4 li img {
    margin-right: 0;
    width: 260px;
    height: 190px;
}

.page-content .gallery ul.thumbnail.col-5 li {
    width: 205px;
    height: auto;
    margin-right: 4%;
    margin-bottom: 15px;
}

.page-content .gallery ul.thumbnail.col-5 li img {
    width: 205px;
    height: 168px;

    height: auto;
    margin-right: 20px;
}

.page-content .gallery ul.thumbnail.col-6 li {
    width: 159px;
    height: auto;
    margin-right: 55px;
    margin-bottom: 55px;
}

.page-content .gallery ul.thumbnail.col-6 li img {
    width: 136px;
    height: auto;
    margin-right: 0px;
}

.page-content .gallery ul.thumbnail.col-7 li {
    width: 75px;
    height: auto;
    margin-right: 10px;
}

.page-content .gallery ul.thumbnail.col-8 li {
    width: 64px;
    height: auto;
    margin-right: 10px;
}

.page-content .gallery ul.thumbnail.col-9 li {
    width: 60px;
    height: auto;
    margin-right: 8px;
}

.page-content .gallery .thumbnail li img:hover {
    opacity: 1;
    -webkit-transition: opacity .6s ease-in-out;
    -moz-transition: opacity .6s ease-in-out;
    -o-transition: opacity .6s ease-in-out;
    -ms-transition: opacity .6s ease-in-out;
    transition: opacity .6s ease-in-out;
}

/*Gallery style in content bar
================================================*/
.gallery.gall ul.thumbnail li span.fade {
    position: absolute;
    top: 60px;
    left: 65px;
    display: block;
    opacity: 0;
    width: 220px;
    height: 153px;
}

.content_bar {
    overflow: hidden;
}

.content_bar .gallery .thumbnail {
    margin-left: 0;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    margin: 0 auto;
    margin-right: 10px;
    width: 105%;
}

.content_bar .gallery ul.thumbnail li {
    margin: 0;
    margin-left: 0;
    float: left;
    position: relative;
    margin-right: 26px;
    width: 216px;
    height: 165px;
    margin-bottom: 25px;
    text-align: center;
}

.content_bar .gallery ul.thumbnail.col-1 li {
    width: 200px;
    height: 150px;
    display: block;
    float: none;
    margin: 0 auto;
}

.content_bar .gallery ul.thumbnail.col-2 li {
    width: 270px;
    height: 155px;
}

.content_bar .gallery ul.thumbnail.col-3 li {
    width: 260px;
    margin: 0 4% 3% 0;
}

.content_bar .gallery ul.thumbnail.col-4 li {
    width: 204px;
    margin-right: 24px;
    margin-bottom: 10px;
}

.content_bar .gallery ul.thumbnail.col-5 li {
    width: 162px;
    margin-right: 20px;
}

.content_bar .gallery ul.thumbnail.col-6 li {
    width: 134px;
    margin-bottom: 15px;
    margin-right: 16px;
}

.content_bar .gallery ul.thumbnail.col-7 li {
    width: 77px;
    height: 46px;
    margin-right: 10px;
}

.content_bar .gallery ul.thumbnail.col-8 li {
    width: 64px;
    height: 35px;
    margin-right: 10px;
}

.content_bar .gallery ul.thumbnail.col-9 li {
    width: 60px;
    height: 32px;
    margin-right: 8px;
}

.content_bar .gallery .thumbnail li img {
    -webkit-transition: all .65s linear;
    -moz-transition: all .6s linear;
    -o-transition: all .6s linear;
    -ms-transition: all .6s linear;
    transition: all .6s linear;
    border: 1px solid #c3cccd;
    padding: 5px;
}

.content_bar .gallery .thumbnail li img:hover {
    opacity: 1;
}

.content_bar ul.thumbnail li span.fade {
    position: absolute;
    top: 60px;
    left: 65px;
    display: block;
    opacity: 0;
    width: 220px;
    height: 153px;
}

@media only screen and (max-width: 1032px) {
    /* =Small device menu sytle
======================================*/
    /* Mean Menu styles. hide the link until viewport size is reached */
    #header .top-header {
        text-align: center;
    }

    a.meanmenu-reveal {
        display: none;
    }

    .mean-container #main-menu {
        padding: 0;
        margin: 0;
        height: 10px;
    }

    /* when under viewport size, .mean-container is added to body */
    .mean-container .mean-bar {
        /* float: left; */
        width: 100%;
        position: relative;
        background: #000;
        padding: 4px 0;
        min-height: 42px;
        z-index: 999999;
    }

    .mean-container a.meanmenu-reveal {
        width: 22px;
        height: 22px;
        /* padding: 13px 13px 11px 13px; */
        position: absolute;
        top: 8px;
        right: 10px !important;
        cursor: pointer;
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        text-indent: -9999em;
        line-height: 22px;
        font-size: 1px;
        display: block;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 700;
    }

    .mean-container a.meanmenu-reveal span {
        display: block;
        background: #fff;
        height: 3px;
        margin-top: 3px;
    }

    .mean-container .mean-nav {
        float: left;
        width: 100%;
        background: #000;
        margin-top: 33px;

        margin-bottom: 30px;
    }

    .mean-container .mean-nav ul {
        padding: 0;
        margin: 0;
        width: 100% !important;
        list-style-type: none;
    }

    .mean-container .mean-nav ul li {
        position: relative;
        float: left;
        width: 100%;
        margin-left: 0;
    }

    .mean-container .mean-nav ul li a {
        display: block;
        float: left;
        width: 1000%;
        padding: 1em 5%;
        margin: 0;
        text-align: left;
        color: #fff;
        border-bottom: 1px solid #383838;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        text-decoration: none;
        text-transform: uppercase;
    }

    .mean-container .mean-nav ul li ul li {
        width: 100%;
    }

    .mean-container .mean-nav ul li li a {
        width: 80%;
        padding: 1em 10%;
        border-bottom: 1px solid #f1f1f1;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        opacity: 0.75;
        filter: alpha(opacity=75);
        text-shadow: none !important;
        visibility: visible;
    }

    .mean-container .mean-nav ul li.mean-last a {
        border-bottom: none;
        margin-bottom: 0;
    }

    .mean-container .mean-nav ul li li li a {
        width: 70%;
        padding: 1em 15%;
    }

    .mean-container .mean-nav ul li li li li a {
        width: 60%;
        padding: 1em 20%;
    }

    .mean-container .mean-nav ul li li li li li a {
        width: 50%;
        padding: 1em 25%;
    }

    .mean-container .mean-nav ul li a:hover {
        background: #252525;
        background: rgba(255, 255, 255, 0.1);
    }

    .mean-container .mean-nav ul li a.mean-expand {
        width: 26px;
        height: 26px;
        border: none !important;
        padding: 12px !important;
        text-align: center;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        font-weight: 700;
    }

    .mean-container .mean-nav ul li a.mean-expand:hover {
        background: none;
    }

    .mean-container .mean-push {
        float: left;
        width: 100%;
        padding: 0;
        margin: 0;
        clear: both;
    }

    .mean-nav .wrapper {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    #content_wrapper .element-list .row.elm {
        margin-left: -15px;
        margin-right: -15px;
    }

    #content_wrapper .element-list .col-elm {
        padding-right: 15px;
        padding-left: 15px;
    }

    #content_wrapper.content .cnt-wrap {
        margin-left: 0;
    }

    #content_wrapper .element-list.single .col-elm {
        padding-right: 15px;
        padding-left: 15px;
    }

    #content_wrapper .element-list.single .row.elm {
        margin-left: -15px;
        margin-right: -15px;
    }

    #respond #commentform input[type="text"], .contactform input[type="text"], .contactform input[type="email"] {
        width: 100%;
    }

    #site_features .f-content {
        height: auto;
    }

    #content_wrapper.content.single .cnt-data iframe {
        max-width: 100%;
        max-height: auto;
    }
}