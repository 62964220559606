html, body {
    font-size: 15px;
    margin: 0;
}
h1{
    font-size: 30px;
}
h2{
    font-size: 26px;
}
h3{
    font-size: 24px;
}
h4{
    font-size: 22px;
}
h5{
    font-size: 20px;
}
h6{
    font-size: 18px;
}
line{
    color: #3d3d3d;
    margin: 1em 0;
}
ul,ol{
    margin: 1em 0;
}
b,strong{
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
}
h1, h2, h3,h4, h5,h6{
    font-family: 'Montserrat', sans-serif;
    color: #1a1a1a;
}

.top_feature_container {
    background: url("https://seofriuli.it/wp-content/uploads/2015/12/seo-dark-2.jpg") fixed center top repeat-x;
    width: 100%;
    min-height: 450px;
    color: #fff;
}
.confirm-email{
  display: none;
}
