html, body {
    font-family: 'Raleway', sans-serif;
}
h1, h2, h3,h4, h5,h6{
    font-family: 'Raleway', sans-serif;
    color: #1a1a1a;
}
.top_feature_container{
    min-height: 445px;
    padding-bottom: 40px;
}
.top_feature_container .header{
    margin-top: 21px;
}
.top_feature_container .header .contact_detail{
    text-align: right;
}
.top_feature_container .header .contact_detail p{
    display: inline-block;
    color: #ED3C3A;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 0;
}
.top_feature_container .header .contact_detail span{
    vertical-align: super;
    margin-left: 10px;
    font-size: 18px;
}
.top_feature_content{
    text-align:center;

    margin-top: 84px;
}
.top_feature_content h1{
    color: #fff;
    font-size: 52px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
}
.top_feature_content h1 span{
    color: #ed3c3a;
}
.top_feature_content p{
    color: #fff;
    font-size: 20px;
}
.fg-placeholder{
    display: inline-block;
    margin-top: -100px;
    float: right;
}
.feature_item_content{
    margin-top: 72px;
    margin-right: 30px;
}
.feature_item_content .feature_item{
    margin-bottom: 17px;

}
.feature_item_content .feature_item span{
    float: left;
    font-size: 30px;
    margin-right: 27px;
    margin-top: 10px;
    background: #ED3C3A;
    border-radius: 61%;
    color: #fff;
    width: 50px;
    height: 50px;
    padding: 5px 6px 0px 10px;
}
.feature_item_content .feature_item .feature_item_inner{
    overflow: hidden;
    margin-left: 15px;
}
.feature_item_content .feature_item .feature_item_inner h2{
    font-size: 21px;
    font-weight: 700;
}
.feature_item_content .feature_item .feature_item_inner p{
    font-size: 18px;
}
.form_wrapper_custom{
    margin-top: -70px;
    border: 1px solid #ddd;
    padding: 12px 20px 0px 20px;
    background: #fff;
    margin-left: 84px;
    border-radius: 7px;
    text-align: center;
    webkit-box-shadow: 0 3px 0 rgba(232, 232, 232, 0.55);    -moz-box-shadow: 0 3px 0 rgba(232, 232, 232, 0.55);    box-shadow: 0 3px 0 rgba(232, 232, 232, 0.55);
}
.testimonial_heading_container{
    text-align: center;
    background: #f2f2f2;
    margin-bottom: 57px;
    margin-top: 60px;
}
.testimonial_heading_container h2{
    color: #666;
    font-size: 34px;
    font-weight: 300;
    margin: 13px 0 14px 0;
}
.testimonial_item_content{
    margin-bottom: 5px;
}
.testimonial_item_content .testimonial_item{
    width: 46.7%;
    display: inline-block;
    padding: 18px 12px 17px 13px;
    margin-bottom: 52px;
    border: 1px solid #dfdfdf;
    webkit-box-shadow: 0 3px 0 rgba(232, 232, 232, 0.55);
    -moz-box-shadow: 0 3px 0 rgba(232, 232, 232, 0.55);
    box-shadow: 0 3px 0 rgba(232, 232, 232, 0.55);
}
.testimonial_item_content .testimonial_item:nth-child(odd){
    /* background: #ff0000; */
    margin-right: 6%;
}
.testimonial_item_content .testimonial_item .testimonial_item_inner{
    overflow: hidden;
}
.testimonial_item_content .testimonial_item img{
    float: left;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    margin: 3px 15px 0 0;
}
.testimonial_item_content .testimonial_item .testimonial_item_inner p{
    line-height: 27px;
    margin-bottom: 18px;
    font-size: 18px;
}
.testimonial_item_content .testimonial_item .testimonial_item_inner a{
    color: #e93d3d;
    text-decoration: none;
    font-size: 17px;
}
.footer_container{
    background: #2d2d2d;
    text-align: center;
    color: #fff;
    padding: 13px 0 0px 0;
}
.footer_container p{
    color:#fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .form_wrapper_custom{
        padding:12px 20px 0px 14px;
    }
    .testimonial_item_content .testimonial_item{
        width: 97%;	
        margin-bottom: 40px;
    }
    .testimonial_item_content .testimonial_item:nth-child(odd){
        margin-left: 0;
    }
}
@media only screen and (min-width: 767px) and (max-width: 992px) {
    .form_wrapper_custom{
        margin: 0 101px 0 112px;
        margin-top: 50px;
    }
    .testimonial_item_content .testimonial_item{
        width: 100%;	
        margin-bottom: 40px;
    }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
}
@media only screen and (max-width: 990px){
    .container {
        overflow: hidden;
    }
    .top_feature_container .header {
        text-align: center;
    }	
    .top_feature_container .header .logo{
        margin-bottom: 25px;
    }
    .top_feature_container .header .contact_detail{
        text-align: center;
    }
    .top_feature_content {
        text-align: center;
        margin-top: 70px;
    }
    .top_feature_content h1 {
        font-size: 40px;
    }
    .form_wrapper_custom{
        margin: 40px 125px 25px 126px;
    }
    .testimonial_item_content .testimonial_item{
        width: 100%;	
        margin-bottom: 40px;
        text-align: center;
    }
    .feature_item_content{
        margin-right: 0;
    }
    .feature_item_content .feature_item{
        text-align: center;

        margin-bottom: 45px;
    }
    .feature_item_content .feature_item .feature_item_inner{
        margin-left: 0;
    }
    .feature_item_content .feature_item .feature_item_inner h3{
        margin-top: 25px;
    }
    .feature_item_content .feature_item span{
        float: none;	
        margin: 0;
        background: #ED3C3A;    
        border-radius: 50%;    color: #fff;    
        width: 50px;   
        height: 50px;  
        padding: 9px 10px 9px 12px;
        margin-bottom: 26px;
        /* display: inline-block; */
    }
}
@media only screen and (max-width: 640px){
    .form_wrapper_custom{
        margin: 40px 82px 25px 82px;
    }

}
@media only screen and (max-width: 480px){
    .template_feature_container{
        text-align: center;
    }
    .form_wrapper_custom{
        margin: 0 auto;
        width: 280px;
    }
}
@media only screen and (max-width: 350px){
    .form_wrapper_custom{
        margin: 0 auto 0 -3px;
        width: 255px;
        padding: 2px 12px 2px 5px;
    }
}